import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseLayout } from "../Component/BaseLayout";
import { ListProjects } from "../data/AssetsData";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


export function ListProPage() {
  
  return (
    <BaseLayout titleTab="Project List">
      <main className="pt-12">
        <section className=" overflow-clip">
          <div className="container mx-auto mt-10 px-12 md:mt-14 lg:mt-20">
            <div className=" max-w-md">
              {/* <h1 className="mb-2 text-3xl font-semibold text-black"></h1> */}
              <h2 className=" max-w-4xl text-4xl font-semibold">Projects</h2>
              <br />
              <span className="font-light text-2xl">
                Emphasize the smooth integration and experience with your SaaS.
              </span>
            </div>
          </div>
        </section>
        <section>
          {ListProjects &&
            ListProjects?.map((index, datas) => {
              return (
                <div className="mt-10 md:mt-14 lg:mt-20 border-t" key={datas}>
                  <div className="first:border-t">
                    <div className="container mx-auto flex flex-col lg:flex-row px-12 py-12">
                      <div className="flex flex-1 lg:flex-col justify-between items-start flex-nowrap w-full">
                        <div className="mr-16">
                          <h3 className="text-3xl font-semibold text-gray-600">
                            {index.name_project}
                          </h3>
                          <br />
                          <span className="text-4xl font-normal">
                            {index.desc}
                          </span>
                        </div>
                        <div className="flex justify-end">
                          <Link
                            className="relative text-sm font-medium leading-none outline-none h-11 flex items-center "
                            to={index.url}
                          >
                            <span className="mr-3"> See All Project</span>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </Link>
                        </div>
                      </div>
                      <div className="pointer-event-none relative mt-8 flex-1 aspect-[4/3] md:mt-0">
                        <div className="">
                          <img
                            className="h-full w-full object-cover"
                            src={index.image}
                            alt={index.name_project}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </section>
      </main>
    </BaseLayout>
  );
}
