import { v4 as uuidv4 } from "uuid";

export const DetailUniqlo = [
  {
    image_header:"https://res.cloudinary.com/rumahindah/image/upload/v1703602093/digix/uniqlo/project_uniqlo_head_desktop_ec9efe.svg",
    project: "Uniqlo",
    title:
      "Uniqlo Redesign: By allowing a minimalist and elegant design like ecommerce in general, this lures customers closer, especially among teenagers.",
    type: [
      {
        id: uuidv4(),
        title: "Ecommerce",
      },
    ],
    topic: [
      {
        id: uuidv4(),
        title: "SEO",
      },
      {
        id: uuidv4(),
        title: "UI/UX",
      },
      {
        id: uuidv4(),
        title: "Mobile",
      },
    ],
    overview:{
        titleOverview:"UNIQLO is a Japanese company that provides casual clothing for everyone.",
        titleOverview2:"This project takes about 3 weeks, and is only for UI and UX design for Mobile Apps, not Websites."
    },
    about_us:{
        titleAbout:"UNIQLO is able to produce so many unique products because our business model brings together the entire clothing manufacturing process-from planning and design to production, distribution, and retail. UNIQLO's market share is growing worldwide as we develop radical new materials together with the world's best fabric technology innovators and create basic designs using superior natural materials.",
        titleAbout2:"With an appearance that seemed rigid and only focused on the elderly and adults, we deliberately tried to redesign the application, because we wanted to attract customers from teenagers and young people.",
        titleAbout3:"These are the first, second and third stages of Design Thinking"
    },
    Planing:{
        planing:"After conducting the research, we found many insights that were generated.",
        planing2:"We simplify all the important operational tasks, so admins can focus on the things that matter: providing customers with a relaxed service experience and increasing customer satisfaction. It sounds like a catchy phrase, but it's just that.",
        planing3:"With the research that we have done, we then brainstormed to achieve the goals that have been determined."
    },
    execute:{
      executes:"After getting the insight results, then we present the results of our ideas in the form of wireframes and high fidelity in accordance with predetermined User Flow and Information Architecture.",
      executes2:"Define, and Ideate are the second and third processes in Design Thinking."
    },
    imageSlide:{
      image1:"https://res.cloudinary.com/rumahindah/image/upload/v1704113120/digix/uniqlo/wf_uniqlo_desktop_1_c6o4ri.svg"
    },
    testing:{
      testings:"After Wireframe, High Fidelity is made, then we proceed to Prototype and Testing. Testing was carried out by several customers who often shop on the Uniqlo application, using Usability Testing techniques.",
      testings2:"Determine Usability Testing criteria according to customers who usually shop using the application, so that it is maximized.",
      testings3:"Wireframe and high fidelity creation are the third and fourth parts of Design Thinking"
    },
    product_launch:{
      product_launch1:"Once the product has been created, it's time for us to proudly provide you with more information about your chosen business model.",
      product_launch2:"The technology used in the construction is according to a predetermined agreement."
    },
    image_uiux:"https://res.cloudinary.com/rumahindah/image/upload/v1704003800/digix/uniqlo/all_image_uniqlo_vcnyuo.svg"
  },
];


export const DetailDumaCoffee = [
  {
    image_header:"https://res.cloudinary.com/rumahindah/image/upload/v1703601699/digix/damiun%20coffee%20shop/project_damiun_head_desktop_roopxl.svg",
    project: "Damiun Coffee Shop.",
    title:
      "Online Ordering: The functionality to allow customers to place coffee orders and make payments directly through the coffee shop's website.",
    type: [
      {
        id: uuidv4(),
        title: "Ecommerce",
      },
    ],
    topic: [
      {
        id: uuidv4(),
        title: "SEO",
      },
      {
        id: uuidv4(),
        title: "UI/UX",
      },
      {
        id: uuidv4(),
        title: "mobile",
      },
    ],
    overview:{
        titleOverview:"As a company, we often take on low-budget projects whenever we identify a cause. Damiun is owned by a hardworking man, whose address is in Tangerang Regency, is currently in the process of being initially offline to digitizing by using our services",
        titleOverview2:"As we are in the making to become a Digitalizing Damiun, no other project would make more sense. Anyway, with all of this said, go ahead with your e-based web development project"
    },
    about_us:{
        titleAbout:"As a company, we often take on low-budget projects whenever we identify a cause. Chibiw is owned by a hardworking man, whose addreDamiun coffee shop is a coffee shop service that sells various kinds of coffee and snacks and contemporary drinks. Currently Damiun is experiencing a decline in income so the impact is being felt by the owner.",
        titleAbout2:"At Damiun, we have conducted customer research to find out how many or how many problems customers experience when ordering products at Damiun, as a result we get a lot of insights to put forward as a solution for Damiun.",
        titleAbout3:"These are the first, second and third stages of Design Thinking"
    },
    Planing:{
        planing:"As a company, we often take on low-budget projects whenever we identify a cause. Chibiw is owned by a hardworking man, whose addreDamiun coffee shop is a coffee shop service that sells various kinds of coffee and snacks and contemporary drinks. Currently Damiun is experiencing a decline in income so the impact is being felt by the owner.",
        planing2:"At Damiun, we have conducted customer research to find out how many or how many problems customers experience when ordering products at Damiun, as a result we get a lot of insights to put forward as a solution for Damiun.",
        // planing3:"With the research that we have done, we then brainstormed to achieve the goals that have been determined."
    },
    execute:{
      executes:"After getting the insight results, then we present the results of our ideas in the form of wireframes and high fidelity in accordance with predetermined User Flow and Information Architecture.",
      executes2:"Wireframe and high fidelity creation is part of Ideate and Prototyping of Design Thinking"
    },
    imageSlide:{
      image1:"https://res.cloudinary.com/rumahindah/image/upload/v1704113170/digix/damiun%20coffee%20shop/wireframe_damiun_-_mockups_sk6vbw.svg"
    },
    testing:{
      testings:"As a company, we often take on low-budget projects whenever we identify a cause. Chibiw is owned by a hardworking man, whose addreDamiun coffee shop is a coffee shop service that sells various kinds of coffee and snacks and contemporary drinks. Currently Damiun is experiencing a decline in income so the impact is being felt by the owner.",
      testings2:"After Ideating and Prototyping, we then test the product to users according to predetermined criteria",
      testings3:"Wireframe and high fidelity creation are the third and fourth parts of Design Thinking"
    },
    product_launch:{
      product_launch1:"After the product has been made, it's time for us to proudly provide further information with you regarding the business model that has been determined",
      product_launch2:"The technology used in the construction is according to a predetermined agreement."
    },
    image_uiux:"https://res.cloudinary.com/rumahindah/image/upload/v1703601699/digix/damiun%20coffee%20shop/all_image_damiun_ep0pfw.svg"
  },
];

export const DetailFlix = [
  {
    image_header:"https://res.cloudinary.com/rumahindah/image/upload/v1703602091/digix/flixs%20-%20web%20agency/project_flixs_head_desktop_adlnwg.svg",
    project: " Coffee Shop.",
    title:
      "Flixs: A design agency specializing in user interface (UI) development, user experience (UX), and graphic design.",
    type: [
      {
        id: uuidv4(),
        title: "Agency",
      },
    ],
    topic: [
      {
        id: uuidv4(),
        title: "SEO",
      },
      {
        id: uuidv4(),
        title: "UI/UX",
      },
      {
        id: uuidv4(),
        title: "Website",
      },
    ],
    overview:{
        titleOverview:"As a company, we often take on low-budget projects whenever we identify a problem. Flixs is owned by a hardworking man, located somewhere in the capital city, currently on his second web build",
        titleOverview2:"This web creation is based on the results of a joint decision between Flixs and the technology that will be used later."
    },
    about_us:{
        titleAbout:"FlixsFlixs was founded with the aim to create visually appealing and functional experiences for users. The team at Flixs focuses on creating designs that are engaging, intuitive, and meet client needs by combining appealing aesthetics with optimized functionality.",
        titleAbout2:"Projects at Flixs may range from interface design for mobile and web applications, the creation of responsive and engaging websites, to the development of a strong brand identity through creative graphic design. The Flixs team works collaboratively with clients to understand their vision and deliver innovative design solutions that fit their needs.",
        titleAbout3:"These are the first, second and third stages of Design Thinking"
    },
    Planing:{
        planing:"We start with in-depth user research by understanding user needs, habits, and preferences by investigating thoroughly.",
        planing2:"We then proceed with User-centric Understanding, to Dig into the core of the user experience to create relevant and user-focused designs.",
        // planing3:"With the research that we have done, we then brainstormed to achieve the goals that have been determined."
    },
    execute:{
      executes:"After getting the insight results, then we present the results of our ideas in the form of wireframes and high fidelity in accordance with predetermined User Flow and Information Architecture.",
      executes2:"Wireframe and high fidelity creation is part of Ideate and Prototyping of Design Thinking"
    },
    imageSlide:{
      image1:"https://res.cloudinary.com/rumahindah/image/upload/v1703601765/digix/flixs%20-%20web%20agency/wf_flixs_desktop_uduaau.svg"
    },
    testing:{
      testings:"After Wireframe, High Fidelity is made, then we proceed to Prototype and Testing. Testing is done by several customers who often shop at web design Flixs, using A/B Testing techniques. And determine the A / B criteria according to the relevant customer so that it is maximized.",
      // testings2:"After Ideating and Prototyping, we then test the product to users according to predetermined criteria",
      testings3:"Wireframe and high fidelity creation are the third and fourth parts of Design Thinking"
    },
    product_launch:{
      product_launch1:"After the product has been made, it's time for us to proudly provide further information with you regarding the business model that has been determined",
      product_launch2:"The technology used in the construction is according to a predetermined agreement."
    },
    image_uiux:"https://res.cloudinary.com/rumahindah/image/upload/v1703601699/digix/damiun%20coffee%20shop/all_image_damiun_ep0pfw.svg"
  },
]