import { useState } from "react";
// import { SliderPorto } from "../data/sliderPorto";
import { ButtonSlider } from "./Button/ButtonSlider";

export function SliderComp({ SliderImg }) {
  const [slider, setSliderState] = useState(1);

  let [over, setOver] = useState(false);

  let buttonstyle = {
    display: "",
    backgroundColor: "",
  };

  const nextImage = () => {
    if (slider !== SliderImg.length) {
      setSliderState(slider + 1);
    } else if (slider === SliderImg.length) {
      setSliderState(1);
    }
  };

  const prevImage = () => {
    if (slider !== 1) {
      setSliderState(slider - 1);
    } else if (slider === 1) {
      setSliderState(SliderImg.length);
    }
  };
  if (over) {
    buttonstyle.backgroundColor = "";
    buttonstyle.display = "block";
  } else {
    buttonstyle.backgroundColor = "none";
  }

  return (
    <div>
      <div
        style={buttonstyle}
        onMouseOver={() => setOver(true)}
        onMouseOut={() => setOver(false)}
      >
        {SliderImg.map((index, obj) => {
          return (
            <div
              id={index.id}
              className={`flex justify-center items-center bg-violet-600 rounded-lg ${slider === obj + 1 ? "slide active-anim" : "slide"}`}
            >
              <div className="h-32 lg:h-96 w-32 lg:w-96">
                <img
                  className="w-full h-full block"
                  src={`${index.img}`}
                  alt=""
                />
              </div>
              {/* <img src={process.end.PUBLIC_URL + ``} alt="" /> */}
            </div>
          );
        })}
        <div>
          <ButtonSlider moveSlide={nextImage} direction={"next"} />
          <ButtonSlider moveSlide={prevImage} direction={"prev"} />
        </div>
      </div>
    </div>
  );
}
