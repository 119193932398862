import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function LayoutDetailProject({
  image_header,
  project,
  title,
  type,
  topic,
  overview,
  about_us,
  Planing,
  imageSlide,
  testing,
  product_launch,
  image_uiux,
  mobileTrue
}) {
  return (
    <>
      <header>
        <section className="relative mt-8 overflow-hidden rounded-lg">
          <div className="pointer-events-none absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black/10"></div>
          <img
            height="725"
            width="1289"
            src={image_header}
            className="h-full w-full bg-background-offset"
            alt=""
          />
        </section>
        <div className="mx-auto mt-8 max-w-4xl border-b pb-12 md:mt-14 lg:mt-20">
          <h1 className="text-3xl text-gray-500">{project}</h1>
          <br />
          <h3 className="text-3xl">{title}</h3>
        </div>
      </header>
      <div className="mx-auto mt-8 max-w-4xl border-b pb-8">
        <div className="flex flex-col md:flex-row flex-nowrap justify-start">
          <span className="text-xl font-medium basis-1/5">service</span>
          <div className="grid gap-y-4 grid-cols-1 basis-4/5">
            <div className="grid gap-x-4 gap-y-3 md:gap-y-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-6 relative h-min justify-start w-full">
              {type?.map((indextype, datas) => {
                return (
                  <div key={datas}>
                    <span className="text-yellow-800 bg-yellow-100 py-2 rounded-full font-medium px-3 text-center text-xs">
                      {indextype.title}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-8 max-w-4xl border-b pb-8">
        <div className="flex flex-col md:flex-row flex-nowrap justify-start">
          <span className="text-xl font-medium basis-1/5">Topic</span>
          <div className="grid gap-y-4 grid-cols-1 basis-4/5">
            <div className="grid gap-x-4 gap-y-3 md:gap-y-0 grid-cols-1 md:grid-cols-2 lg:grid-cols-7 relative h-min justify-start w-full">
              {topic?.map((indextype, datas) => {
                return (
                  <div
                    key={datas}
                    className="bg-yellow-100 py-2 rounded-full px-3 flex flex-row text-yellow-800 justify-between"
                  >
                    <span className=" font-medium  text-center text-xs">
                      {indextype.title}
                    </span>
                    <FontAwesomeIcon icon={faCircleExclamation} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-8 max-w-4xl pb-8">
        <h3 className="text-xl font-medium text-gray-500">Project Overview</h3>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {overview.titleOverview}
        </span>
        <br />
        <br />
        <div className="flex flex-row mb-10">
          <div className="border-l-2 border-black basis-[5%]"></div>
          <span className="font-light basis-[95%] text-sm italic">
            {overview.titleOverview2}
          </span>
        </div>
        <h3 className="text-xl font-medium text-gray-500">About Us</h3>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {about_us.titleAbout}
        </span>
        <br />
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {about_us.titleAbout2}
        </span>
        <br />
        <br />
        <div className="flex flex-row">
          <div className="border-l-2 border-black basis-[5%]"></div>
          <span className="font-light basis-[95%] text-sm italic">
            {about_us.titleAbout3}
          </span>
        </div>
        <h3 className="text-xl font-medium text-gray-500 mt-10">Planning</h3>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {Planing.planing}
        </span>
        <br />
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {Planing.planing2}
        </span>
        <br />
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {Planing.planing3}
        </span>
        <h3 className="text-xl font-medium text-gray-500 mt-10">Execute</h3>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          After getting the insight results, then we present the results of our
          ideas in the form of wireframes and high fidelity in accordance with
          predetermined User Flow and Information Architecture.
        </span>
        <br />
        <br />
        <div className="flex flex-row">
          <div className="border-l-2 border-black basis-[5%]"></div>
          <span className="font-light basis-[95%] text-sm italic">
            Wireframe and high fidelity creation is part of Ideate and
            Prototyping of Design Thinking
          </span>
        </div>
      </div>
      <figure className={`my-8 md:my-14`}>
        <div className="w-full overflow-hidden rounded-3xl">
          <div className={`bg-[#967CF6] rounded-lg  flex justify-center ${mobileTrue ? "pt-32":"py-32"}`}>
            <img
              src={imageSlide}
              className="w-1/2 h-1/2 bg-background-offset"
              alt=""
            />
          </div>
        </div>
      </figure>
      <div className="mx-auto mt-8 max-w-4xl pb-8">
        <h3 className="text-xl font-medium text-gray-500">Testing</h3>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {testing.testings}
        </span>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {testing.testings2}
        </span>
        <br />
        <br />
        <div className="flex flex-row mb-10">
          <div className="border-l-2 border-black basis-[5%]"></div>
          <span className="font-light basis-[95%] text-sm italic">
            {testing.testings3}
          </span>
        </div>
        <h3 className="text-xl font-medium text-gray-500">
          Product Launch & Improvement
        </h3>
        <br />
        <span className="font-normal text-lg leading-normal text-justify">
          {product_launch.product_launch1}
        </span>
        <br />
        <br />
        <div className="flex flex-row mb-10">
          <div className="border-l-2 border-black basis-[5%]"></div>
          <span className="font-light basis-[95%] text-sm italic">
            {product_launch.product_launch2}
          </span>
        </div>
      </div>

      <div className="mx-auto mt-8 max-w-4xl pb-8">
        <h3 className="text-3xl text-center font-medium text-gray-800">
          The following is a display of the {project} that has been released.
        </h3>
      </div>

      <figure className="my-8 md:my-14 max-w-4xl mx-auto">
        <div className="w-full overflow-hidden rounded-3xl relative">
          <img
            class="h-auto w-full"
            src={image_uiux}
            alt="Dia colour pallete. "
            width="2000"
            height="1200"
          />
        </div>
      </figure>
    </>
  );
}
