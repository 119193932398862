import { BaseLayout } from "../Component/BaseLayout";
import { LayoutDetailProject } from "../Component/LayoutDetailProject";
import { DetailUniqlo } from "../data/DetailProject";

export function DetailProPageUniqlo() {
  return (
    <BaseLayout titleTab="Detail Project">
      <main className="pt-12 lg:mt-12">
        <div className="container mx-auto px-12">
          {DetailUniqlo?.map((index, data) => {
            return (
              <LayoutDetailProject
                image_header={index.image_header}
                topic={index.topic}
                project={index.project}
                title={index.title}
                type={index.type}
                overview={index.overview}
                about_us={index.about_us}
                Planing={index.Planing}
                imageSlide={index.imageSlide.image1}
                testing={index.testing}
                product_launch={index.product_launch}
                mobileTrue={true}
                image_uiux={index.image_uiux}
              />
            );
          })}
        </div>
      </main>
    </BaseLayout>
  );
}
