import { v4 as uuidv4 } from "uuid";

export const SliderPorto = [
  {
    id: uuidv4(),
    title: "test",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1704108221/digix/icon/product_design_1_-_how_we_help_you_is3ck6.svg",
  },
  {
    id: uuidv4(),
    title: "test",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1704107583/digix/icon/product_design_2_-_how_we_help_you_yzg4bq.svg",
  },
];
