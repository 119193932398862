import { Menu, Transition } from "@headlessui/react";
import { useEffect, useState, Fragment, useRef } from "react";
import scrollDirection from "../data/NavbarHooks";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { DigiX } from "../assets/img";

export function NavbarComponent() {
  const [scrollBg, setScrollBg] = useState(null);
  const [stringBg, setStringBg] = useState("");
  const [customOpen, setCustomOpen] = useState(true);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);


  function buttonClicked() {
    setCustomOpen(!customOpen);
  }
  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      event.target !== buttonRef.current
    ) {
      setCustomOpen(false);
    }
  };
  // const [popUpMenu]

  useEffect(() => {
    bgTransparant();
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const changeNavbarColor = () => {
    if (window.scrollY >= 640) {
      setScrollBg(true);
    } else {
      setScrollBg(false);
    }
  };

  const bgTransparant = () => {
    if (window.location.href === "https://digixcorps.com/") {
      setStringBg("bg-transparent");
    } else if (window.location.href === "http://localhost:3000/") {
      setStringBg("bg-transparent");
    }
    // else if (window.location.href === "http://localhost:3000/") {
    //   setStringBg("bg-transparent");
    // }
    else {
      setStringBg("bg-white border-b-gray-200");
    }
    
    

  };

  window.addEventListener("scroll", changeNavbarColor);
  const scrollDirections = scrollDirection();

  console.log(customOpen);
  return (
    <div className={``}>
      <nav
      ref={menuRef}
        className={`z-30 w-full block duration-300 fixed translate-y-0 border-b-current ease-[cubic-bezier(0.90, 0, 0.05, 1)] transition-[transform,border-color] ${
          scrollDirections === "down"
            ? `${"-top-24 duration-400"}`
            : `top-0 ${
                scrollBg
                  ? "bg-white border-b border-b-gray-200"
                  : `duration-600 ${stringBg}`
              }`
        }`}
      >
        <Menu>
          <div>
            <Menu.Button
              ref={buttonRef}
              onClick={buttonClicked}
              class="inline-flex  items-center right-4 p-2 w-10 h-10 top-3  z-40 fixed justify-center text-sm text-gray-900 rounded-lg md:hidden hover:bg-gray-50 focus:outline-none  "
            >
              <div>
                {customOpen ? (
                  <svg
                    class="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M1 1h15M1 7h15M1 13h15"
                    />
                  </svg>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faClose} />
                  </>
                )}
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="fixed z-30 right-0 w-full py-10 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    <div className="flex items-center flex-col gap-8">
                      <div className="items-center gap-6 text-base font-medium leading-relaxed flex flex-col ">
                        <Link to="/services">Service</Link>
                        <Link to="/project-list">Project</Link>
                        <Link to="/about-us">About Us</Link>
                      </div>
                      <div className="flex items-center gap-4">
                        <div className=" sm:block">
                          <a
                            href="https://calendly.com/digixcorps/website"
                            className="h-11 relative inline-flex items-center overflow-hidden text-md 
                font-medium leading-none outline-none transition-all border border-gray-800 hover:ring-4 hover:ring-gray-300 rounded-md px-5"
                          >
                            <span>Contact Us</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
        <div className="flex items-center justify-between py-4 container mx-auto px-10">
          <div className="flex items-center gap-2">
            <Link to="/">
              <div className="h-16 w-16">
                <DigiX className="block h-full w-full" />
              </div>
            </Link>
          </div>
          <div className="flex items-center gap-8">
            <div className="hidden items-center gap-6 text-base font-medium leading-relaxed md:flex ">
              <Link to="/services">Service</Link>
              <Link to="/project-list">Project</Link>
              <Link to="/about-us">About Us</Link>
            </div>
            <div className="flex items-center gap-4">
              <div className="hidden sm:block">
                <a
                  href="https://calendly.com/digixcorps/website"
                  className="h-11 relative inline-flex items-center overflow-hidden text-md 
                font-medium leading-none outline-none transition-all border border-gray-800 hover:ring-4 hover:ring-gray-300 rounded-md px-5"
                >
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </nav>
    </div>
  );
}
