import { styled } from "styled-components";
import { BaseLayout } from "../Component/BaseLayout";

const HeightService = styled.div`
  height: height: max(652px, 803px);
`;
export function ServicesPage() {
  return (
    <BaseLayout titleTab="Services">
      <main className="pt-12">
        <section className=" overflow-clip">
          <div className="container mx-auto mt-10 px-9 sm:px-20 md:mt-14 lg:mt-20">
            {/* <h1 className="mb-2 text-3xl font-semibold text-black"></h1> */}
            <h2 className=" max-w-3xl text-4xl lg:text-7xl font-semibold">
              This is our workflow for digitizing your business
            </h2>
          </div>
          <HeightService className="relative mt-12 md:mt-20">
            <div
              className=" sticky top-0 py-12"
              style={{ top: "calc(50vh - 326px)" }}
            >
              <div
                className="absolute top-1/2 flex h-full -translate-x-1/2 -translate-y-1/2 flex-col items-center"
                style={{ left: "196px" }}
              >
                <div className="-mt-6 hidden h-full animate-shake lg:block">
                  <svg
                    viewBox="0 0 5 496"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-full"
                  >
                    <path
                      d="M2.36425 2L2.36424 494"
                      stroke="currentColor"
                      stroke-width="3"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M2.53492 3.27734C2.53492 13.4761 2.39227 23.6664 3.2717 33.7798C3.96781 41.7852 3.48501 50.4113 2.93489 58.3881C2.33035 67.1538 2.91383 76.1492 2.91383 84.933C2.91383 91.8447 2.91383 98.7563 2.91383 105.668C2.91383 107.156 2.91383 108.643 2.91383 110.131C2.91383 110.566 2.9111 112.575 2.99804 112.488C3.32047 112.166 3.67166 111.491 3.67166 111.731C3.67166 114.123 3.32577 116.468 3.29275 118.846C3.19976 125.541 2.23055 132.219 2.15601 138.928C2.07952 145.812 2.06173 152.717 2.15601 159.6C2.23161 165.118 2.91383 170.691 2.91383 176.272C2.91383 179.359 2.91383 182.447 2.91383 185.534"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M2.91343 185.533C2.91343 194.627 2.91343 203.721 2.91343 212.815C2.91343 224.08 1.01886 235.234 1.01886 246.517C1.01886 250.719 0.976419 254.924 1.01886 259.126C1.07559 264.742 2.1556 270.286 2.1556 275.904C2.1556 280.385 2.07263 284.875 2.1556 289.355C2.17764 290.545 2.65125 296.686 2.91343 296.555"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M2.9133 293.797C2.9133 305.038 2.9133 316.279 2.9133 327.52C2.9133 335.497 3.50306 473.465 1.93454 481.307C1.31998 484.38 2.01874 487.381 2.01874 490.486"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </svg>
                </div>
              </div>
             
                  <div className="border-b">
                    <p className="container mx-auto px-20 py-3 text-sm font-semibold lg:hidden">
                      Strategy
                    </p>
                    <div className=" overflow-hidden">
                      <div
                        className=" container mx-auto flex flex-col gap-4 px-20 py-6"
                        style={{ minWidth: "1536px" }}
                      >
                        <div className="flex items-center">
                          <div className=" mr-6 hidden w-28 lg:block">
                            <p className="text-sm font-semibold">Strategy</p>
                          </div>
                          <div className="flex grow gap-3">
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Discovery
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Definition
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Ideate
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="mr-6 hidden w-28 lg:block"></div>
                          <div className="flex grow gap-3">
                            <div className=" relative shrink-0 ml-12">
                              <div className="border border-yellow-400 py-4 px-5 rounded-lg bg-yellow-400 w-60 flex justify-center">
                                <span className="font-semibold text-sm">
                                  Research
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-b">
                    <p className="container mx-auto px-20 py-3 text-sm font-semibold lg:hidden">
                      Design
                    </p>
                    <div className=" overflow-hidden">
                      <div
                        className=" container mx-auto flex flex-col gap-4 px-20 py-6"
                        style={{ minWidth: "1536px" }}
                      >
                        <div className="flex items-center">
                          <div className=" mr-6 hidden w-28 lg:block">
                            <p className="text-sm font-semibold">Design</p>
                          </div>
                          <div className="flex grow gap-3">
                            <div className=" relative shrink-0 ml-64">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Wireframe
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  User Testing
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Final Design
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  User Testing
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="mr-6 hidden w-28 lg:block"></div>
                          <div className="flex grow gap-3">
                            <div className=" relative shrink-0 ml-72">
                              <div className="border border-yellow-400 py-4 px-5 rounded-lg bg-yellow-400 w-40 flex justify-center">
                                <span className="font-semibold text-sm">
                                  Concept
                                </span>
                              </div>
                            </div>
                            <div className="relative shrink-0 ml-24">
                              <div className="border border-yellow-400 py-4 px-5 rounded-lg bg-yellow-400 w-40 flex justify-center">
                                <span className="font-semibold text-sm">
                                  Design System
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <p className="container mx-auto px-20 py-3 text-sm font-semibold lg:hidden">
                      Development
                    </p>
                    <div className=" overflow-hidden">
                      <div
                        className=" container mx-auto flex flex-col gap-4 px-20 py-6"
                        style={{ minWidth: "1536px" }}
                      >
                        <div className="flex items-center">
                          <div className=" mr-6 hidden w-28 lg:block">
                            <p className="text-sm font-semibold">
                              Development
                            </p>
                          </div>
                          <div className="flex grow gap-3">
                            <div className=" relative shrink-0 ml-80">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Software Architecture
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-32">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg w-64">
                                <span className="font-semibold text-sm">
                                  Development
                                </span>
                              </div>
                            </div>
                            <div className=" relative shrink-0 ml-0">
                              <div className="border border-gray-500 py-4 px-5 rounded-lg">
                                <span className="font-semibold text-sm">
                                  Launch
                                </span>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="mr-6 hidden w-28 lg:block"></div>
                          <div className="flex grow gap-3">
                            
                            <div className="relative shrink-0 ml-[46rem]">
                              <div className="border border-yellow-400 py-4 px-5 rounded-lg bg-yellow-400 w-40 flex justify-center">
                                <span className="font-semibold text-sm">
                                  Design System
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
            
          </HeightService>
        </section>
        <section className="mt-10 md:mt-14 border-t py-5">
          <div className="container mx-auto flex px-12">
            <div className="flex flex-col md:flex-row relative flex-nowrap">
              <div className="flex basis-2/5 flex-col justify-center items-start flex-nowrap w-full lg:pr-20">
                <h3 className="text-3xl text-gray-500 font-semibold">
                  Our Services.
                </h3>
                <h3 className="text-3xl text-gray-900 font-semibold my-2">
                  The full list.
                </h3>
                <span className="text-lg text-gray-900 font-light">
                  Each delivery is a succession of cycles through Strategy,
                  Design, and Development. And it's the stir-up of all these
                  elements that create interfaces that people will love.
                </span>
              </div>
              <div className="basis-3/5 mt-5 lg:mt-0 lg:ml-7">
                <div className="grid gap-x-8 gap-y-8 grid-cols-2 lg:grid-cols-3 relative h-min justify-start">
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Strategy
                    </h3>
                    <span className="text-lg text-gray-700 font-medium">
                      Discover
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Auditing
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Business Analysis
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Data Analysis
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      User Research
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Benchmarking
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Design
                    </h3>
                    <span className="text-lg text-gray-700 font-medium">
                      Interface
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      User Experience Design
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Interaction Design
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      User Interface Design
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Design System
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      User Testing
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Development
                    </h3>
                    <span className="text-lg text-gray-700 font-medium">
                      Front End
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      React
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Typescript
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Angular
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Tailwind
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Bootstrap
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Product Definition
                    </h3>
                    {/* <span className="text-lg text-gray-700 font-medium">
                      Discover
                    </span> */}
                    <span className="text-sm text-gray-700 font-light">
                      Proof of Concept
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Minimum Viable Product
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Full Product
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Growth Strategy
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Brand
                    </h3>

                    <span className="text-sm text-gray-700 font-light">
                      Brand Strategy & Identity
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Logo Design
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Brand Guidelines
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Marketing Material
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Back End
                    </h3>

                    <span className="text-sm text-gray-700 font-light">
                      Node.js
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Python
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Golang
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Javascript
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      Multimedia
                    </h3>
                    <span className="text-sm text-gray-700 font-light">
                      Motion Design
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Illustration
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Video
                    </span>
                  </div>
                  <div className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <h3 className="text-xl text-gray-900 font-semibold">
                      DevOps
                    </h3>
                    <span className="text-sm text-gray-700 font-light">
                      AWS
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Azure
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Docker
                    </span>
                    <span className="text-sm text-gray-700 font-light">
                      Kubernetes
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
      </main>
    </BaseLayout>
  );
}
