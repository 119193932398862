import { Page } from "../helper/route";
import { Footer } from "./Footer";
import { NavbarComponent } from "./Navbar";
import { styled } from "styled-components";


const ContactLay = styled.div`
  background-image: url(https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1769&q=80);
  height: 400px;
  width: 730px;
`;

const BgContact = styled.div`
  background-color: hsl(var(--color-background) / 0.63);
`;

export function BaseLayout({children, titleTab}) {
  return (
    <Page title={titleTab}>
      <div className="bg-body" style={{ display: "contents" }}>
        <NavbarComponent />
            {children}
            <section className="mt-10 border-t border-b">
        <div className="container mx-auto flex px-12 justify-center py-12">
          <ContactLay className=" object-cover bg-cover rounded-[3.2rem]">
            <BgContact className="w-full h-full text-white rounded-[3.2rem] flex flex-col justify-center items-center px-10 py-7">
              <div className="text-center">
                <span className="text-base md:text-xl font-medium">
                  Curious about how long and how much it will takes to build
                  your product?
                </span>
              </div><br/>
              <div>
                <a
                  className="bg-[#4364F9] rounded-lg py-3 px-3  h-auto flex text-center text-white"
                  href="https://calendly.com/digixcorps/website"
                >
                  Contact Us
                </a>
              </div>
            </BgContact>
          </ContactLay>
        </div>
      </section>
        <Footer/>
      </div>
    </Page>
  );
}
