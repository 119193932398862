import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LayoutSection } from "../Component/LayoutSection";
import { SliderPorto } from "../data/sliderPorto";
import { BaseLayout } from "../Component/BaseLayout";
import { Link } from "react-router-dom";
import { Chose, WhatWeDo, imageHomeSlide } from "../data/AssetsData";

export function HomePage() {
  return (
    <BaseLayout titleTab="Home">
      <LayoutSection textORLink={true}>
        <div className="mr-6 flex flex-col flex-nowrap lg:justify-between relative w-full h-full">
          <h3 className="text-4xl lg:text-6xl text-gray-900 font-semibold">
            Your best digital transformation partner
          </h3>

          <div>
            <span className="leading-loose text-base lg:text-xl text-gray-700 font-normal">
              Will be delivered the best technology solutions that will help to
              achieve your Digital Transformation Goal`s.
            </span>
          </div>
          <a
            className="relative text-sm font-medium leading-none outline-none border h-11 flex justify-center items-center bg-white rounded-lg px-5 mt-6 w-32"
            href="https://calendly.com/digixcorps/website"
          >
            <span>Book Now</span>
          </a>
        </div>
      </LayoutSection>
      <div className="container mx-auto px-20 my-14 lg:my-24">
        <div className="flex items-center flex-col flex-nowrap justify-start relative w-full h-min flex-none">
          <div className="mx-auto flex max-w-2xl items-center flex-col mb-12 lg:mb-20">
            <h3 className="text-center text-5xl text-black font-medium">
              Why should you choose us?
            </h3>
            <p className="text-center text-2xl mt-7 md:mt-12">
              Software that literally design itself
            </p>
          </div>
          <div className="grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative h-min justify-start w-full">
            {Chose &&
              Chose?.map((index, item) => {
                return (
                  <div className="flex-col border border-gray-300 rounded-2xl p-5 justify-start flex-nowrap justify-self-start overflow-hidden relative w-full items-start h-64 grid gap-1 self-start">
                    <div>{index.img}</div>
                    <div className=" outline-none flex flex-col justify-start flex-shrink-0">
                      <h4 className=" text-base font-semibold">{index.text}</h4>
                    </div>
                    <div className=" outline-none flex flex-col justify-start flex-shrink-0">
                      <h4 className=" text-sm text-left">{index.desc}</h4>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <section className="mt-10 md:mt-14 lg:mt-20">
        <div className="container mx-auto px-20">
          <h2 className="text-5xl">How we help you?</h2>
        </div>
        <div className="mt-4 md:mt-6 lg:mt-8">
          <LayoutSection
            SliderOrImg={true}
            Text="Web Development"
            Desc="create a website that suits the needs of the business and help develop it with an attractive appearance and content. "
            SlideImgs={imageHomeSlide}
            textORLink={false}
          />
          <LayoutSection
            SliderOrImg={true}
            Text="Product Design"
            Desc="You will make products that are cost-effective to use, without compromising the value, quality and benefits of the product."
            SlideImgs={SliderPorto}
            textORLink={false}
          />
        </div>
      </section>
      <section className="mt-10 md:mt-14 lg:mt-20">
        <div className="container mx-auto px-20">
          <h2 className="text-5xl">What do we do?</h2>
        </div>
        <div className="container mx-auto px-20 mt-4 md:mt-6 lg:mt-8">
          <div className="grid gap-x-8 gap-y-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative h-min justify-start w-full">
            {WhatWeDo &&
              WhatWeDo?.map((item, index) => {
                return (
                  <div id={index} className="flex flex-col justify-between flex-nowrap justify-self-start overflow-hidden relative w-full items-start self-start">
                    <div className={`bg-[#967CF6] rounded-lg px-16 lg:px-32 py-14 lg:py-20`}>
                      <img
                        src={item.img}
                        className="h-40 w-full"
                        alt=""
                      />
                    </div>
                    <br />
                    <div className=" outline-none flex flex-col justify-start flex-shrink-0">
                      <h4 className=" text-2xl font-semibold">
                        {item.text}
                      </h4>
                    </div>
                    <br />
                    <div className=" outline-none flex flex-col justify-start flex-shrink-0">
                      <h4 className=" text-base font-light text-left text-gray-600 break-all">
                        {item.desc}
                      </h4>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="mt-10 md:mt-14 lg:mt-20">
        <div className="first:border-t">
          <div className="container mx-auto flex flex-col lg:flex-row px-20 py-12">
            <div className="flex flex-1 flex-col justify-between items-start flex-nowrap w-full">
              <h3 className="text-3xl text-gray-900 font-semibold">
                Our Projects
              </h3>

              <div className="my-10">
                <h3 className="text-xl font-semibold text-gray-900">
                  Web Development
                </h3>
                <br />
                <span className="text-lg text-gray-700 font-normal">
                  create a website that suits the needs of the business and help
                  develop it with an attractive appearance and content.
                </span>
                <br />
                <br />

                <div className="flex flex-row flex-nowrap relative mt-2">
                  {/* <ReactJss /> */}
                  &nbsp;&nbsp;
                  {/* <Analytics /> */}
                  &nbsp;&nbsp;
                  {/* <Figma /> */}
                </div>
              </div>
              <div className="flex justify-end">
                <Link
                  className="relative text-sm font-medium leading-none outline-none h-11 flex items-center "
                  to="/project-list"
                >
                  <span className="mr-3"> See All Project</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            </div>
            <div className="pointer-event-none relative mt-8 flex-1 aspect-[4/3] md:mt-0">
              <div className="">
                <img
                  className="h-full w-full object-cover"
                  src="https://res.cloudinary.com/rumahindah/image/upload/v1703601714/digix/damiun%20coffee%20shop/our_project_damiun_-_desktop_h0ifv9.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseLayout>
  );
}
