import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDribbble,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

export function Footer() {
  return (
    <section className="my-10">
      <div className="flex flex-row justify-between max-w-[11rem] mx-auto h-auto w-full">
       
        <a className="cursor-pointer h-8 w-8" href="https://www.instagram.com/digix.studio/">
          <button aria-label="instagram" className="h-8 w-8 text-black rounded bg-transparent absolute justify-center items-center flex cursor-pointer whitespace-nowrap">
            <span className="gap-2 h-auto flex flex-row items-center justify-center w-full">
              <span className="text-center">
                <div className=" w-5 h-5 mx-4">
                  <FontAwesomeIcon className="block w-full h-full" icon={faInstagram} />
                </div>
              </span>
            </span>
          </button>
        </a>
        <a className="cursor-pointer h-8 w-8" href="https://dribbble.com/digiXStudio">
          <button aria-label="dribble" className="h-8 w-8 text-black rounded bg-transparent absolute justify-center items-center flex cursor-pointer whitespace-nowrap">
            <span className="gap-2 h-auto flex flex-row items-center justify-center w-full">
              <span className="text-center">
                <div className=" w-5 h-5 mx-4">
                  <FontAwesomeIcon className="block w-full h-full" icon={faDribbble} />
                </div>
              </span>
            </span>
          </button>
        </a> 
        
      </div>
      <div className="flex flex-row justify-center max-w-xs  mx-auto mt-8">
        <span>
          <h1>ⓒ2023 All rights reserved.</h1>
        </span>
      </div>
    </section>
  );
}
