import { BaseLayout } from "../Component/BaseLayout";

export function ContactPage() {
  return (
    <BaseLayout titleTab="About Us">
      <main className="pt-12">
        <section className=" overflow-clip">
          <div className="container mx-auto mt-10 px-12 md:mt-14 lg:mt-20">
            
            <h2 className=" max-w-3xl text-7xl font-semibold">
              About Us
            </h2>
          </div>
         
        </section>
        <section className="mt-10 md:mt-14 border-b border-t py-5">
          <div className="container mx-auto flex px-12">
            <div className="flex flex-col md:flex-row relative flex-nowrap justify-between">
              <div className="flex basis-2/5 flex-col justify-between items-start flex-nowrap w-full pr-20 h-48">
                <div>
                <h3 className="text-3xl text-gray-500 font-semibold">
                Who we are.
                </h3>
                <h3 className="text-3xl text-gray-900 font-semibold my-2">
                DigiX
                </h3></div>
                <div className="w-[41%]">
                  <span>
                  Team Involved
                  </span>
                  <div className="flex flex-row justify-between mt-2">
                    <img src="https://inforadar.disway.id/upload/85f7b38bb80467bf2e0d5af1770d2864.jpg" className="rounded-full h-10 w-10 object-cover bg-cover" alt="" />
                    <img src="https://inforadar.disway.id/upload/85f7b38bb80467bf2e0d5af1770d2864.jpg" className="rounded-full h-10 w-10 object-cover bg-cover" alt="" />

                    <img src="https://inforadar.disway.id/upload/85f7b38bb80467bf2e0d5af1770d2864.jpg" className="rounded-full h-10 w-10 object-cover bg-cover" alt="" />

                  </div>
                </div>
                
              </div>
              <div className="basis-3/5 ml-0 lg:ml-7 mt-4 lg:mt-0">
               <span className=" text-base lg:text-lg font-light">
               Ayres is more than just a SaaS provider; we are your strategic partner in achieving excellence in business. Our software solutions are designed to cater to various industries, including finance, marketing, logistics, and more. By embracing Ayres, your business gains a competitive edge, unlocking untapped potential, and accelerating growth.
               </span>
              </div>
            </div>
          </div>
        </section>
        <section className="mt-10 md:mt-14 py-5">
          <div className="container mx-auto flex px-12">
            <div className="flex flex-col md:flex-row relative flex-nowrap">
              <div className="flex basis-2/5 flex-col justify-center items-start flex-nowrap w-full pr-20">
                <h3 className="text-xl lg:text-3xl text-gray-500 font-semibold">
                  Our Services.
                </h3>
                <h3 className="text-xl lg:text-3xl text-gray-900 font-semibold my-2">
                  The full list.
                </h3>
                <span className="text-sm lg:text-lg text-gray-900 font-light">
                  Each delivery is a succession of cycles through Strategy,
                  Design, and Development. And it's the stir-up of all these
                  elements that create interfaces that people will love.
                </span>
              </div>
              <div className="basis-3/5 lg:ml-7 mt-5 lg:mt-0">
                <div className="flex flex-col relative h-min justify-start">
                  <div className="flex flex-col border-b pb-4">
                    <h4 className=" text-xl">People first.</h4>
                    <span>
                    Be your real, authentic self. Grow, challenge, fail and succeed, be yourself.
                    </span>
                  </div>
                 
                </div>
                <div className="flex flex-col relative h-min justify-start mt-5">
                  <div className="flex flex-col border-b pb-4">
                    <h4 className=" text-xl">Teamwork.</h4>
                    <span>
                    Go further together with curiosity and empathy, always.
                    </span>
                  </div>
                 
                </div>
                <div className="flex flex-col relative h-min justify-start mt-5">
                  <div className="flex flex-col border-b pb-4">
                    <h4 className=" text-xl">Paspire higher.</h4>
                    <span>
                    Always aim for perfection, even though you will never reach it.


                    </span>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
       
      </main>
    </BaseLayout>
  );
}
