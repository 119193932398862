import { v4 as uuidv4 } from "uuid";
import {
  Dollar,
  LikeShapes,
  People,
  ReceiptEdit,
  Message,
  Integration,
} from "../assets/img";


export const imageHomeSlide = [
  {
    id: uuidv4(),
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1704107584/digix/icon/web_development_2_-_how_we_help_you_dkkpbz.svg",
    alt: "asdasd",
  },
  {
    id: uuidv4(),
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1704107586/digix/icon/Web_development_-_how_w_help_you_wc8esi.svg",
    alt: "asdasd",
  },
];

export const ListProjects = [
  {
    // id: uuidv4(),
    image: "https://res.cloudinary.com/rumahindah/image/upload/v1705211228/digix/damiun%20coffee%20shop/Dumion_2x_lcc0lo.png",
    url:"/project-detail/duma-coffee",
    name_project: "Damiun Coffee Shop.",
    desc: "A minimalist and economical coffee shop in the Tangerang area",
  },
  {
    // id: uuidv4(),
    image: "https://res.cloudinary.com/rumahindah/image/upload/v1705206449/digix/uniqlo/Uniqlo_3x_ufwwe7.png",
    url:"/project-detail/uniqlo",
    name_project: "Uniqlo.",
    desc: "Redesigning the Uniqlo app with a more minimalist look.",
  },
  {
    // id: uuidv4(),
    image: "https://res.cloudinary.com/rumahindah/image/upload/v1705224651/digix/flixs%20-%20web%20agency/Flix_2x_l5u4ec.png",
    url:"/project-detail/flixs",
    name_project: "Flixs.",
    desc: "An agency that provides UI/UX and graphic design and branding services for all walks of life.",
  },
];


export const Chose = [
  {
    text:"Expertise",
    desc:"With a deep understanding of industry best practices and the latest technologies, which enables us to create reliable, scalable and user-friendly SaaS products.",
    img: <People />,
  },
  {
    text:"Customization",
    desc:"Every business has unique needs and requirements, therefore we work closely with our clients to develop a SaaS solution tailored to their specific goals and objectives.",
    img: <ReceiptEdit />,
  },
  {
    text:"Transparency",
    desc:"Open communication and transparency throughout the development process to keep our clients informed at every step, and work with them to ensure that their SaaS product meets their requirements and expectations.",
    img: <Message />,
  },
  {
    text:"Competitive Pricing",
    desc:"Offering competitive pricing for our services, ensuring that clients receive a high-quality SaaS product without having to dig deep into their pockets.",
    img: <Dollar />,
  },
  {
    text:"Support",
    desc:"We don't just deliver software and walk away - we're there to provide ongoing support and maintenance services post-launch.",
    img: <LikeShapes />,
  },
  {
    text:"Seamless Integration",
    desc:"SaaS platforms can integrate smoothly with other important tools or software that businesses already use with seamless integration saving time and effort.",
    img: <Integration />,
  } 
  
]

export const WhatWeDo = [
  {
    text:"Product or Project Defition",
    desc:"Define every detail of each feature and benefit of your product by using user story format where it can be easily understandable form business and technical perspective.",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1703692163/digix/flat/project_definition_m7is4b.svg",
    paddingx: "5"
  },
  {
    text:"Design & Prototype",
    desc:"Once we have detailed product definition and features, We put each of feature into a well design and user-friendly Prototype so you will get the idea of how your final product will looks like.",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1703692163/digix/flat/design_prototype_lk4es0.svg",
    paddingx: "5"
  },
  {
    text:"Product Market Fit",
    desc:" Help finding your ideal customers and get in touch with them, by showing and demo the prototype they will get the idea of how your product will works and could benefit them, they will give feedback as improvement notes before we build the actual product",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1703692162/digix/flat/product_market_fit_kilslq.svg",
    paddingx: "16"

  },
  {
    text:"High Expectation Customer",
    desc:"During marketfit process, we will invite them to be your high expectation cutsomers, means they will have early access once the product is available on market. This way you will have your customers even before you build and launch the product.",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1703692163/digix/flat/high_expectation_customer_e7ibbc.svg",
    paddingx: "20"

  },
  {
    text:"Product Development",
    desc:"From prototype that validated by your high expectation customers, we start build and develop the actual product by using scrum methodology to make sure we are on schedule to release each feature and launch the product. we also will demo the progress every sprint.",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1703692163/digix/flat/product_development_c4tspy.svg",
    paddingx: "20"
  },
  {
    text:"Product Launch & Improve",
    desc:"It encompasses the iterative process of refining the user interface and user experience based on the gathered insights, industry trends, and user expectations, aiming to ensure continuous enhancement and optimal user satisfaction.",
    img: "https://res.cloudinary.com/rumahindah/image/upload/v1703692163/digix/flat/product_launch_sqqpsw.svg",
    paddingx: "24"

  },
]