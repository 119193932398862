import { styled } from "styled-components";
import { SliderComp } from "./SliderComp";

const ContainerLay = styled.div`
  container-type: inline-size;
  position: relative;
`;

const SubLayout = styled.div`
  padding-left: 5rem;
  padding-right: 5rem;
`;

export function LayoutSection({
  textORLink,
  Text,
  Url,
  Desc,
  SlideImgs,
  SliderOrImg,
  hImg,
  wImg,
  children,
}) {
  return (
    <div className={textORLink ? "bg-[#FBD718] h-screen" : ""}>
      <ContainerLay className={`pb-5 pt-12 lg:pb-12 group ${textORLink? "h-full lg:h-[90vh]" :"border-b first:border-t transition-colors hover:bg-gray-50"}`}>
        <SubLayout className={`container mx-auto flex flex-col-reverse lg:flex-row ${textORLink ? "mt-14 lg:mt-24 h-full lg:h-[60vh]":""}`}>
          <div className={`flex flex-1 lg:flex-col justify-between items-start ${textORLink ? "basis-1/4 flex-reverse":""}`}>
            <>
              {textORLink ? (
                <>
                 {children}
                </>
              ) : (
                <>
                  <div className="mr-6">
                    <a className="elevated-link" href={Url}>
                      <h3 className="text-3xl text-gray-800 font-medium">{Text}</h3><br/>
                      <p className="text-lg font-light max-w-lg text-justify">{Desc}</p>
                    </a>
                  </div>
                
                </>
              )}
            </>
          </div>

          <div className={`pointer-event-none relative mt-8 flex-1 aspect-[4/3] mb-6 lg:mb-0 md:mt-0 ${textORLink ? "lg:basis-1/2":""}`}>
            {SliderOrImg ? (
              <SliderComp SliderImg={SlideImgs} />
            ) : (
              <div className=" max-w-lg lg:max-w-full mx-auto ">
                <img
                  className="h-full w-full object-cover"
                  src="https://res.cloudinary.com/rumahindah/image/upload/v1690634360/digix/Frame_92972647_sko68z.svg"
                  height={hImg}
                  width={wImg}
                  alt=""
                />
              </div>
            )}
          </div>
        </SubLayout>
      </ContainerLay>
    </div>
  );
}
