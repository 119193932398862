import { Route, Routes } from "react-router-dom";
import { HomePage } from "./View/HomePage";
import { ContactPage } from "./View/ContactPage";
import { ServicesPage } from "./View/ServiceProPage";
import { ListProPage } from "./View/ListProPage";
import { DetailProPageDumaCoffee } from "./View/DetailProPageuma";
import { DetailProPageUniqlo } from "./View/DetailProPageUniqlo";
import { DetailProPageFlixs } from "./View/DetailProPageFlixs";
import ScrollToTop from "./Component/ScrollToTop";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NRDZ5G9W'
}
TagManager.initialize(tagManagerArgs)
function App() {
  window.dataLayer.push({
    event: 'pageview',
    page: {
      url: document.location.origin + document.location.pathname + document.location.search,
      // title: title,
    }
  });
  window.dataLayer.push({
    event: 'event',
  
  });
  return (
    <ScrollToTop>
      <Routes>
        {/* <IndexPage sad/> */}
        <Route exact path="/" element={<HomePage />} />
        <Route path="about-us" element={<ContactPage />} />
        <Route path="services" element={<ServicesPage />} />
        <Route path="project-list" element={<ListProPage />} />
        <Route
          path="project-detail/duma-coffee"
          element={<DetailProPageDumaCoffee />}
        />
        <Route path="project-detail/uniqlo" element={<DetailProPageUniqlo />} />
        <Route path="project-detail/flixs" element={<DetailProPageFlixs />} />
      </Routes>
    </ScrollToTop>
  );
}

export default App;
