import { BaseLayout } from "../Component/BaseLayout";
import { LayoutDetailProject } from "../Component/LayoutDetailProject";
import { DetailDumaCoffee } from "../data/DetailProject";


export function DetailProPageDumaCoffee() {
  return (
    <BaseLayout titleTab="Detail Project">
            <main className="pt-12 lg:mt-12">
        <div className="container mx-auto px-12">
        {DetailDumaCoffee?.map((index, data) => {
            return (
              <LayoutDetailProject
                image_header={index.image_header}
                project={index.project}
                topic={index.topic}
                title={index.title}
                type={index.type}
                overview={index.overview}
                about_us={index.about_us}
                imageSlide={index.imageSlide.image1}
                Planing={index.Planing}
                
                testing={index.testing}
                product_launch={index.product_launch}
                image_uiux={index.image_uiux}
              />
            );
          })}
        </div>
      </main>
    </BaseLayout>
  );
}
