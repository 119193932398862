import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ButtonSlider({ direction, moveSlide, style }) {
  return (
    <button
      onClick={moveSlide}
      style={style}
      type="button"
      className={
        direction === "next"
          ? "flex items-center justify-center rounded-full outline-none h-10 w-10 absolute right-2 top-1/3 lg:top-1/2 z-10 transition-colors bg-black text-white"
          : "lex items-center justify-center rounded-full outline-none h-10 w-10 absolute  left-2 top-1/3 lg:top-1/2 z-10 transition-colors bg-black text-white"
      }
    >
      {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
      <FontAwesomeIcon className="" icon={direction === "next" ? faArrowRight : faArrowLeft} />
    </button>
  );
}
